import React,{useEffect,useState} from 'react'
import { Button,Upload,Modal,Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { setUpdateDataAndUpload } from '../../utils/helper';
import envData from '../../environment.json';

const { Dragger } = Upload;

function ModalUpload({isOpen,setIsOpen,onSetLink,folder=null}) {
    const [fileList,setFileList] = useState([])

    useEffect(()=>{
        if(isOpen){
            setFileList([])
        }
    },[isOpen])

    const propsUpload = {
        accept: "*",
        name: 'file',
        multiple: true,
        beforeUpload: (file) => {
            setFileList(prev=> {
                const fileListMap = new Map(prev.reduce((arr,i)=>[...arr,[i.name,i]],[]))
                if(fileListMap.has(file.name)){
                    return prev
                }
                return [...prev, file]
            });
            return false;
        },
        fileList,
        showUploadList: false,
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const DeleteFile = (itemDelete) =>{
        setFileList(prev=> {
            const fileFilter = prev.filter( i => i.name !== itemDelete.name)
            return fileFilter
        });
        return
    }

    const handlerUpload = async () => {
        const dataUpload = {
            datasource: fileList, 
            type:"videos",
            setUploadProgress: null,
            folder: folder
        }
        let uploadResult = await setUpdateDataAndUpload(dataUpload)
        console.log('uploadResult', uploadResult)
        if(uploadResult.success){
            const protocol = envData.protocol
            const hostname = envData.hostname
            const port = envData.port
            const link = `${protocol}://${hostname}:${port}`
            let path = '/files/'+folder + '/'+uploadResult.fileName[0]
            const linkFile= link + path

            onSetLink(linkFile)
            setFileList([])
            setIsOpen(false)
        }
        return
    }

    const handleCancel = () => {
        setIsOpen(false)
    }

    return (
        <Modal 
            open={isOpen}
            footer={[
                <Button key="cancel" onClick={()=>handleCancel()}>
                    Cancel
                </Button>,
                <Popconfirm title="เมื่อกดแล้วจะอัพโหลดทันที ต้องการอัพโหลดใช่ หรือ ไม่?" description="เมื่อกดแล้วจะอัพโหลดทันที ต้องการอัพโหลดใช่ หรือ ไม่?" onConfirm={()=>handlerUpload()}>
                    <Button key="upload" type="primary">
                    Upload
                    </Button>
                </Popconfirm>
                ,
            ]}
            // onOk={()=>uploadFileToServer()}
        >
            <div>
                <Dragger disabled={fileList.length} {...propsUpload}>
                    <p className="ant-upload-drag-icon">
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    {/* <p className="ant-upload-hint">
                    อัพโหลดได้เฉพาะไฟล์ .ca-bundle และ .crt
                    </p> */}
                </Dragger>
            </div>
            <div>
                {fileList.map((i,index)=>{
                    return(
                    <div key={index}>
                        <span>{i.name}</span>
                        <span><DeleteOutlined style={{color:'red',cursor:'pointer'}} onClick={()=>DeleteFile(i)} /></span>
                    </div>
                    )
                })}
            </div>
        </Modal>
    )
}

export default ModalUpload