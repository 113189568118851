import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Input, Select, Card, Button, Spin, Switch, notification } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined, UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { uniqBy, unionWith, isEqual } from 'lodash'
import { get, put, post, ppost } from '../../utils';
import { setUpdateDataAndUpload } from '../../utils/helper';
import FunctionTable from './Table';
import ModalUpload from '../../component/ModalUpload';
import InputTextEditor from './../../component/Input/InputTextEditor';
import './styles.scss'
import envData from '../../environment.json';
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};

const protocol = envData.protocol
const hostname = envData.hostname
const port = envData.port
const link = `${protocol}://${hostname}:${port}`

function Function() {
    // const quillRef = useState(null);
    const [visable, setVisable] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [versionList, setVersionList] = useState([])
    const [labelList, setLabelList] = useState([])
    const [isOpenUploadVideo, setIsOpenUploadVideo] = useState(false)

    const publishedList = [
        { value: 0, label: 'No' },
        { value: 1, label: 'Yes' }
    ]
    const TypeList = [
        { value: 0, label: 'NEW' },
        { value: 1, label: 'FIXED' }
    ]


    const [versionId, setVersionId] = useState(null)
    const [name, setName] = useState('')
    const [type, setType] = useState(0)
    const [img_url, setImg] = useState('')
    const [description, setDescription] = useState('')
    const [video, setVideo] = useState('')
    const [manual_link, setLink] = useState('')
    const [lable, setLable] = useState(null)
    const [published, setPublished] = useState(0)
    const [isHighlighted, setIshighlighted] = useState(0)
    const [isShowAllRecommended, setIsShowAllRecommended] = useState(0)
    const [projectOnChange, setProjectOnChange] = useState(false);
    const [textEditor, setTextEditor] = useState("");
    const [price, setPrice] = useState(0);

    const [typeSelected, setTypeSelected] = useState(null);
    const [typeOnChange, setTypeOnChange] = useState(false);
    const [categorySelected, setCategorySelected] = useState(null);
    const [categoryOnChange, setCategoryOnChange] = useState(false);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [ribbon, setRibbon] = useState(null);
    const [ribbons, setRibbons] = useState([]);
    const [linkPath, setLinkPath] = useState('');
    const charLengthLink = 255;

    //File
    const [imageName, setImageName] = useState('')
    const [fileList, setFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const fileSelectRef = useRef(null);
    const [imageUrl, setImageUrl] = useState('')


    const setInitialState = () => {
        setVersionId(null)
        setName('')
        setType(0)
        setImg('')
        setDescription('')
        setVideo('')
        setLink('')
        setLable(null)
        setPublished(0)
        setIshighlighted(0)
        setProjectOnChange(false)
        setTypeOnChange(false)
        setCategoryOnChange(false)
        setPrice(0)
        setTypeSelected(null)
        setCategorySelected(null)
        setRibbon(null)
        setLinkPath('')
        setTextEditor('')
        setImageName("")
        setImageUrl("")
        setFileList([])
        setDeleteFileList([])
        setFilePreview(null)
    }

    useEffect(() => {
        getVersion()
        getLable()
        getType()
        getCategory()
        getRibbon()
    }, []);


    const replaceUrlSlug = (inputString) => {
        if (!inputString) {
            return ""
        }
        let modifiedString = inputString.replace(/[^A-Za-z0-9ก-๙]+/g, '-');
        let slug = modifiedString.replace(/^-+|-+$/g, ''); //ตัด - หน้า และหลัง
        slug = slug.toLowerCase(); //แปลงเป็นพิมพ์เล็ก

        return slug;
    }


    const getRibbon = () => {
        let url = '/api/ribbon'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setRibbons(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    const getType = () => {
        let url = '/api/type'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setTypes(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    const getCategory = () => {
        let url = '/api/category'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setCategories(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    const getLable = () => {
        let url = '/api/label'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setLabelList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    const getVersion = () => {
        let url = '/api/version'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setVersionList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }



    const onclickCreateButton = () => {
        setVisable(true)
        setInitialState()
    }

    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisable(true)
        setLoading(true)
        setProjectOnChange(false)
        setTypeOnChange(false)
        setCategoryOnChange(false)

        let url = '/api/function/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setVersionId(response.version_id)
                    setName(response.name)
                    setType(response.type)
                    setImg(response.img_url)
                    setDescription(response.description)
                    setVideo(response.video)
                    setLink(response.manual_link)
                    setLable(response.lable)
                    setPublished(response.published)
                    setIshighlighted(response.highlighted)
                    setIsShowAllRecommended(response.is_show_all_recommended)
                    setTextEditor(response.function_detail)
                    setPrice(response.price)
                    setRibbon(response.ribbon_id)
                    setLinkPath(response.link_path ? response.link_path : '')
                    if (response.types) {
                        const functionIds = response.types.map(item => item.id);
                        const StrTypesIds = JSON.stringify(functionIds)
                        setTypeSelected(StrTypesIds)
                    }
                    if (response.categories) {
                        const categoryIds = response.categories.map(item => item.id);
                        const StrCategoriesIds = JSON.stringify(categoryIds)
                        setCategorySelected(StrCategoriesIds)
                    }
                    setFileList(
                        response.image_file_name === "" || response.image_file_name == null ? [] : response.image_file_name.split(",").map(name => {
                            return {
                                name: name
                            }
                        })
                    )

                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    const onclickSaveButton = async () => {
        try{
            if(!versionId){
                openNotification('Can not save', 'กรุณาเลือก Version')
                return
            }
    
            if(!name){
                openNotification('Can not save', 'กรุณากรอก Name')
                return
            }
    
            if (linkPath.length > 255) {
                openNotification('Can not save', 'This link path length is more than 255 character.',)
                return
            }
            
            const tempLinkPath = linkPath && linkPath.length <= 255 ? replaceUrlSlug(linkPath) : null
            if (tempLinkPath && !validatePath(tempLinkPath)) {
                openNotification('Can not save', 'Link path รูปแบบไม่ถูกต้อง')
                return
            }

            let url = '/api/function'
            let update = post
            if (editId) {
                url = '/api/function/' + editId
                update = put
            }
            setLoading(true)
            
            saveDeleteFileList()

            console.log('fileList', fileList)
            const dataUpload = {
                datasource: fileList, 
                type:"image",
                setUploadProgress: null,
                folder: "images"
            }
            let listFile = fileList.filter(file => Object.keys(file).length !== 1); // TODO check have file upload
            let imageFunction = await setUpdateDataAndUpload(dataUpload)
            let fileName = imageFunction.success && imageFunction.fileName.length ? imageFunction.fileName[0] : ""
            console.log('imageFunction', imageFunction)
            console.log('fileName', fileName)
            if(fileName && listFile.length > 0){ // TODO if upload sucess and have new file
                let uploadData = {
                    name: imageFunction.fileName[0],
                    image_file_name: imageFunction.fileName[0],
                    image_url: imageUrl,
                };
                let uploadUrl = '/api/image'
                let uploadUpdateImage = await post(uploadUrl, uploadData)
            }

            let data = {
                version_id: versionId,
                name: name,
                type: type,
                img_url: img_url,
                image_file_name: fileName,
                description: description,
                video: video,
                manual_link: manual_link,
                lable: lable && (projectOnChange) ? JSON.stringify(lable) : lable,
                published: published,
                isHighlighted: isHighlighted,
                is_show_all_recommended: isShowAllRecommended,
                function_detail: textEditor,
                price: price,
                ribbon_id: ribbon,
                function_type: typeSelected && (typeOnChange) ? JSON.stringify(typeSelected) : typeSelected,
                category: categorySelected && (categoryOnChange) ? JSON.stringify(categorySelected) : categorySelected,
                link_path: tempLinkPath,
            }
            const updateData = await update(url, data)
            console.log('updateData', updateData)
            setEditId(null)
            setVisable(false)
            setLoading(false)
            return
        }catch(error){
            console.log(error)
            setLoading(false)
            return
        }
    }


    const onclickCancelButton = () => {
        setEditId(null)
        setVisable(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Function'
        }
        if (visable) {
            return 'Create - Function'
        }
        return 'Function'
    }
    const functionNewLabel = (lable) => {
        let newLabel;
        try {
            return newLabel = JSON.parse(lable);
        } catch (error) {
            return newLabel = lable ? lable : [];
        }
    }

    const handleProjectChange = (value) => {
        setLable(value);
        setProjectOnChange(true); // Set onChange to true when value changes
    };

    const handleTypeChange = (value) => {
        setTypeSelected(value);
        setTypeOnChange(true);
    };

    const handleCategoryChange = (value) => {
        setCategorySelected(value);
        setCategoryOnChange(true);
    };

    const handleHighlightChange = (checked) => {
        setIshighlighted(checked ? 1 : 0);
        if(checked){
            setIsShowAllRecommended(1)
        }
    };
    
    const handleShowAllRecommended = (checked) => {
        setIsShowAllRecommended(checked ? 1 : 0);
    };

    const openNotification = (message, description) => {
        notification.open({
            message: message,
            description: description,
            icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        });
    };

    const handleFilesInput = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];

            // Check file type
            if (['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
                // Handle valid file type
                const newFileList = unionWith(fileList, [file], isEqual); // Assuming fileList is defined somewhere
                const uniqueFileList = uniqBy(newFileList, 'name');
                setFileList(uniqueFileList);

                // Example of setting file preview
                const filePreviewUrl = URL.createObjectURL(file);
                setFilePreview(filePreviewUrl);
            } else {
                // Handle invalid file type
                openNotification('File type is not allowed to upload.', 'File type is not allowed to upload.');
            }
        }
    };


    const deleteFile = (fileName) => {
        post('/api/delete/image', { fileName })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
            })
            .catch(err => {
                console.log(err.message);
                setLoading(false)
            });
    };

    const saveDeleteFileList = () => {
        deleteFileList.forEach(file => {
            deleteFile(file);
        });
    };

    const deleteSelectedFile = (index) => {
        if (Object.keys(fileList[index]).length === 1) {
            let tempDeleteFileList = [...deleteFileList, fileList[index].name];
            let tempFileList = fileList.slice();
            tempFileList.splice(index, 1);
            setDeleteFileList(tempDeleteFileList);
            setFileList(tempFileList);
            setFilePreview(null);
        } else {
            let tempFileList = fileList.slice();
            tempFileList.splice(index, 1);
            setFileList(tempFileList);
            setFilePreview(null);
        }
        fileSelectRef.current.value = null
    };

    const uploadClickHandler = () => {
        fileSelectRef.current.click();
    }

    const onChangeTextEditor = (value) => {
        setTextEditor(value)
    }

    const validatePath = (linkPath) => {
        const regex = /^[a-zA-Z_-\d.@]+$/

        if (linkPath && !regex.test(linkPath)) {
            return false
        } 
        return true
    }

    return (
        <div className="function-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    {!visable ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visable ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visable ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visable ?
                <FunctionTable onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="function-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="function-main-card" >
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Version'}>
                                            <Select
                                                value={versionId}
                                                onChange={(value) => setVersionId(value)}
                                            >
                                                {versionList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Name'}>
                                            <Input
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Type'}>
                                            <Select
                                                value={type}
                                                onChange={(value) => setType(value)}
                                            >
                                                {TypeList.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Image URL'}>
                                            <Input
                                                value={img_url}
                                                onChange={(event) => setImg(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col> */}
                                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Image name'}>
                                            <Input value={imageName} onChange={(event) => setImageName(event.target.value)} />
                                        </FormItem>
                                    </Col> */}
                                    { /* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Image name'} required>
                                            <Input value={imageName} onChange={(event) => setImageName(event.target.value)} />
                                        </FormItem>
                                    </Col> */ }
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem
                                            {...formItemLayout}
                                            label={'Image'}
                                        >
                                            <Button
                                                value={'large'}
                                                type="primary"
                                                onClick={uploadClickHandler}
                                                disabled={fileList.length >= 1 ? true : false}
                                            >
                                                <UploadOutlined /> Upload
                                            </Button>
                                            <input type="file" ref={fileSelectRef} id="fileSelect" accept="image/*" onChange={handleFilesInput} style={{ display: 'none' }} />
                                            <div className="sale-file-attach-container">
                                                {fileList.map((file, i) =>
                                                    <div className="sale-list-file" key={i}>
                                                        <a target="_blank" rel="noopener noreferrer" href={link + '/images/' + file.name}>{file.name}</a>
                                                        <span className="delete-file-icon" onClick={() => deleteSelectedFile(i)}>
                                                            <CloseOutlined style={{ fontSize: 10, cursor: 'pointer' }} />
                                                        </span>
                                                    </div>)}
                                            </div>
                                        </FormItem>
                                    </Col>
                                    {/* <Row style={{ textAlign: 'center', display: filePreview || (fileList.length > 0) ? '' : 'none' }}>
                                        <Col span={24}>
                                            <Card style={{ background: 'none', textAlign: 'center' }}>
                                                <img
                                                    style={{ maxWidth: '100%', maxHeight: '500px', display: filePreview || (fileList.length > 0) ? '' : 'none' }}
                                                    src={filePreview}
                                                    alt="Uploaded File"
                                                />   </Card>
                                        </Col>
                                    </Row> */}

                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Description'}>
                                            <TextArea
                                                autosize={{ minRows: 10 }}
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'User Guide (Video)'}>
                                            <div style={{ display: 'flex' }}>
                                                <Input
                                                    value={video}
                                                    onChange={(event) => setVideo(event.target.value)}
                                                />
                                                {/* <Button
                                                    onClick={()=>setIsOpenUploadVideo(true)}
                                                >
                                                    Upload
                                                </Button> */}
                                            </div>

                                        </FormItem>
                                        <ModalUpload
                                            isOpen={isOpenUploadVideo}
                                            setIsOpen={(value) => setIsOpenUploadVideo(value)}
                                            folder="videos"
                                            onSetLink={(value) => setVideo(value)}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'User Guide (Link)'}>
                                            <Input
                                                value={manual_link}
                                                onChange={(event) => setLink(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem 
                                            {...formItemLayout} 
                                            label={'Path'}
                                            help={validatePath(linkPath) ? "" : <p style={{ color: "#FF0000", fontSize: '14px' }}><CloseCircleOutlined /> Path สามารถใช้ a-z, A-Z, 0-9 และ เครื่องหมาย - เท่านั้น</p>}
                                            validateStatus={linkPath.length > charLengthLink ? 'error' : 'success'}
                                        >
                                            <Input
                                                // disabled={}
                                                value={linkPath}
                                                onChange={(event) => setLinkPath(event.target.value)}
                                                onBlur={() => setLinkPath(replaceUrlSlug(linkPath))}
                                            />

                                            <div className='validate-wapper'>
                                                <div className='validate-message'>
                                                    {linkPath.length > charLengthLink ? 'The length must not exceed 255 characters.' : ''}
                                                </div>
                                                <div>
                                                    <span className={linkPath.length > charLengthLink ? 'validate-message' : ''} >{linkPath.length}</span>
                                                    /
                                                    <span>{charLengthLink}</span>
                                                </div>
                                            </div>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Project'}>
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Project"
                                                value={lable ? functionNewLabel(lable) : undefined}
                                                onChange={handleProjectChange}
                                            >
                                                {labelList.map(item => <Option key={item.id} value={item.id}>{item.project_name}{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Published'}>
                                            <Select
                                                value={published}
                                                onChange={(value) => setPublished(value)}
                                            >
                                                {publishedList.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Highlight Function'}>
                                            <Switch
                                                checked={isHighlighted}
                                                onChange={handleHighlightChange}
                                            >
                                            </Switch>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Show All Recommended Items'}>
                                            <Switch
                                                checked={isShowAllRecommended}
                                                onChange={handleShowAllRecommended}
                                            >
                                            </Switch>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Price'}>
                                            <Input
                                                value={price}
                                                type='number'
                                                onChange={(event) => setPrice(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Type'}>
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Type"
                                                value={typeSelected ? functionNewLabel(typeSelected) : undefined}
                                                onChange={handleTypeChange}
                                            >
                                                {types.map(item => <Option key={item.id} value={item.id}>
                                                    {/* {item.project_name} */}
                                                    {item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Category'}>
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Category"
                                                value={categorySelected ? functionNewLabel(categorySelected) : undefined}
                                                onChange={handleCategoryChange}
                                            >
                                                {categories.map(item => <Option key={item.id} value={item.id}>
                                                    {/* {item.project_name} */}
                                                    {item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>       <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Ribbon'}>
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Ribbon"
                                                value={ribbon}
                                                onChange={(value) => setRibbon(value)}
                                            >
                                                {ribbons.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Function detail'}>
                                            <InputTextEditor
                                                value={textEditor}
                                                onChangeText={(value)=> onChangeTextEditor(value)}
                                                disabled={false}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default Function;
