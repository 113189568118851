import axios from 'axios'
import { handle401Error } from '../features/storeModal'
import envData from '../environment.json'
// import envDataDev from '../environment-dev.json'

function setUrl(url) {
    const protocol = envData.protocol
    const hostname = envData.hostname
    const port = envData.port
    const serverUrl = `${protocol}://${hostname}:${port}${url}`

    return serverUrl
}

export async function ccall(url, method, data, setUploadProgress) {
    const serverUrl = await setUrl(url)
    return axios({
        method: method,
        url: serverUrl,
        onUploadProgress :(progressEvent)=>{
            if(setUploadProgress){
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
                setUploadProgress(percent)
            }
        },
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        },
        data: data
    })
    .then(response=>{
        return response.data
    })
    .catch(error => {
        if (typeof error.response === 'undefined') {
            error.response = {
                status: 408,
                message: 'Cannot connect to the server'
            }
        }
        throw error
    })
}



export async function call(url, method, data) {
    const serverUrl = await setUrl(url)
    return axios({
        method: method,
        url: serverUrl,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        },
        data: JSON.stringify(data),
    })
    .then(response=>{
        return response.data
    })
    .catch(error => {
        // No response from the server
        // console.log("error at utils.index.js line 21")
        // console.log(error)
        if (typeof error.response === 'undefined') {
            error.response = {
                status: 408,
                message: 'Cannot connect to the server'
            }
        }
        
        if(error.response.status === 401){
            handle401Error(error.response)
            throw error
        }


        throw error
    })
}

export const ppost = async (url, data,setUploadProgress = null) =>{
    return ccall(url, 'post', data, setUploadProgress)
} 


export const get = async (url) =>{
    return call(url, 'get')
} 

export const post = async (url, data) =>{
    return call(url, 'post', data)
}