import React from 'react';
import { Table, message, Button } from 'antd';
import './styles.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import envData from '../../environment.json';
const protocol = envData.protocol
const hostname = envData.hostname
const port = envData.port
const link = `${protocol}://${hostname}:${port}/images`

function ImageTable(props) {
    const columns = [
        {
            title: 'Color ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div onClick={() => props.onClickRow(record)} >
                    {(record.id).toString().padStart(10, "0")}
                </div>
            ),
        },
        {
            title: 'Image Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div onClick={() => props.onClickRow(record)}>
                    {record.name}
                </div>
            ),
        },
        {
            title: 'Image URL',
            dataIndex: 'image_url',
            key: 'image_url',
            render: (text, record) => (
                <div onClick={() => props.onClickRow(record)}>
                    {`${link}${record.image_file_name}`}
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (text, record) => (
                <div style={{ textAlign: 'center', marginLeft: '10px' }}>
                    <CopyToClipboard
                        text={`${link}/${record.image_file_name}`}
                        onCopy={() => message.success('Copy to clipboard', 1)}
                    >
                        <Button size={'small'}>{'Copy Image URL'}</Button>
                    </CopyToClipboard>
                </div>


            )
        },

    ];

    return (
        <div className="project-table-wrapper">
            <Table
                className="project-table"
                loading={props.loading}
                dataSource={props.dataSource}
                columns={columns}
                rowSelection={{
                    ...props.rowSelection,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        // onClick: (event) => props.onClickRow(record),
                    };
                }}
            />

        </div>
    );
}

export default ImageTable;
