import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, Card, Button, Spin } from 'antd';

import { get, put, post } from '../../utils';
import ProjectTable from './Table';

import './styles.scss'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};

function Label() {
    const [visable, setVisable] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [name, setName] = useState('')
    const [projectId, setProjectId] = useState(null)

    useEffect(() => {
        let url = '/api/project'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setProjectList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }, []);

    const onclickCreateButton = () => {
        setVisable(true)
    }

    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisable(true)
        setLoading(true)
        let url = '/api/label/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setName(response.name)
                    setProjectId(response.project_id)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickSaveButton = () => {
        setLoading(true)
        let url = '/api/label'
        let update = post
        let data = {
            project_id: projectId,
            name: name,
        }
        if (editId) {
            url = '/api/label/' + editId
            update = put
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                setEditId(null)
                setVisable(false)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickCancelButton = () => {
        setEditId(null)
        setVisable(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Label'
        }
        if (visable) {
            return 'Create - Label'
        }
        return 'Label'
    }

    return (
        <div className="label-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    {!visable ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visable ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visable ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visable ?
                <ProjectTable onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="label-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="label-main-card" >
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Project'}>
                                            <Select
                                                value={projectId}
                                                onChange={(value) => setProjectId(value)}
                                            >
                                                {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Name'}>
                                            <Input
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default Label;
