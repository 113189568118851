import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Row, Col, Tabs, Form, Input, Select, Card, Button, DatePicker, Spin } from 'antd';

import { get, put, post } from '../../utils';
import ProjectTable from './Table';

import './styles.scss'

const FormItem = Form.Item;
const Option = Select.Option;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};

function Customer() {
    const [visable, setVisable] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [versionList, setVersionList] = useState([])
    const [name, setName] = useState('')
    const [projectId, setProjectId] = useState(null)
    const [versionId, setVersionId] = useState(null)
    const [domainNameOne, setDomainNameOne] = useState(null)
    const [domainNameTwo, setDomainNameTwo] = useState(null)

    const [gmailUserDisplay, setGmailUserDisplay] = useState(null)
    const [gmailUser, setGmailUser] = useState(null)
    const [gmailClientId, setGmailClientId] = useState(null)
    const [gmailClientSecret, setGmailClientSecret] = useState(null)
    const [gmailRefreshToken, setGmailRefreshToken] = useState(null)

    const [systemStartDate, setSystemStartDate] = useState(null)
    const [systemEndDate, setSystemEndDate] = useState(null)
    const [domainStartDate, setDomainStartDate] = useState(null)
    const [domainEndDate, setDomainEndDate] = useState(null)
    const [promotion, setPromotion] = useState(null)

    useEffect(() => {
        getProjectList()
        getVersionList()
    }, []);

    const getProjectList = () => {
        let url = '/api/project'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setProjectList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const getVersionList = () => {
        let url = '/api/version'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setVersionList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const onclickCreateButton = () => {
        setVisable(true)
    }

    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisable(true)
        setLoading(true)
        let url = '/api/customer/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setName(response.name)
                    setProjectId(response.project_id)
                    setVersionId(response.version_id)
                    setDomainNameOne(response.domain_name_one)
                    setDomainNameTwo(response.domain_name_two)

                    setGmailUserDisplay(response.gmail_user_display)
                    setGmailUser(response.gmail_user)
                    setGmailClientId(response.gmail_client_id)
                    setGmailClientSecret(response.gmail_client_secret)
                    setGmailRefreshToken(response.gmail_refresh_token)

                    setSystemStartDate(response.system_start_date)
                    setSystemEndDate(response.system_end_date)
                    setDomainStartDate(response.domain_start_date)
                    setDomainEndDate(response.domain_end_date)
                    setPromotion(response.promotion)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickSaveButton = () => {
        setLoading(true)
        let url = '/api/customer'
        let update = post
        let data = {
            project_id: projectId,
            version_id: versionId,
            name: name,
            domain_name_one: domainNameOne,
            domain_name_two: domainNameTwo,

            gmail_user_display: gmailUserDisplay,
            gmail_user: gmailUser,
            gmail_client_id: gmailClientId,
            gmail_client_secret: gmailClientSecret,
            gmail_refresh_token: gmailRefreshToken,

            system_start_date: systemStartDate ? moment(systemStartDate).format('YYYY-MM-DD') : null,
            system_end_date: systemEndDate ? moment(systemEndDate).format('YYYY-MM-DD') : null,
            domain_start_date: domainStartDate ? moment(domainStartDate).format('YYYY-MM-DD') : null,
            domain_end_date: domainEndDate ? moment(domainEndDate).format('YYYY-MM-DD') : null,
            promotion: promotion,
        }
        if (editId) {
            url = '/api/customer/' + editId
            update = put
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                setEditId(null)
                setVisable(false)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickCancelButton = () => {
        setEditId(null)
        setVisable(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Customer'
        }
        if (visable) {
            return 'Create - Customer'
        }
        return 'Customer'
    }

    return (
        <div className="label-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    {!visable ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visable ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visable ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visable ?
                <ProjectTable onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="customer-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="label-main-card" >
                            <Tabs onChange={() => null} type="card">
                                <TabPane tab={"General"} key={"1"}>
                                    <Form>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Project'}>
                                                    <Select
                                                        value={projectId}
                                                        onChange={(value) => setProjectId(value)}
                                                    >
                                                        {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                                    </Select>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Version'}>
                                                    <Select
                                                        value={versionId}
                                                        onChange={(value) => setVersionId(value)}
                                                    >
                                                        {versionList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                                    </Select>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Name'}>
                                                    <Input
                                                        value={name}
                                                        onChange={(event) => setName(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Domain Name One'}>
                                                    <Input
                                                        value={domainNameOne}
                                                        onChange={(event) => setDomainNameOne(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Form>
                                </TabPane>
                                <TabPane tab={"Gmail"} key={"2"}>
                                    <Form>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Gmail User Display'}>
                                                    <Input
                                                        value={gmailUserDisplay}
                                                        onChange={(event) => setGmailUserDisplay(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Gmail user (E-mail)'}>
                                                    <Input
                                                        value={gmailUser}
                                                        onChange={(event) => setGmailUser(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Gmail Client ID'}>
                                                    <Input
                                                        value={gmailClientId}
                                                        onChange={(event) => setGmailClientId(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Gmail Client Secret'}>
                                                    <Input
                                                        value={gmailClientSecret}
                                                        onChange={(event) => setGmailClientSecret(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Gmail Refresh Token'}>
                                                    <Input
                                                        value={gmailRefreshToken}
                                                        onChange={(event) => setGmailRefreshToken(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Form>
                                </TabPane>
                                <TabPane tab={"System Information"} key={"3"}>
                                    <Form>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'System Start Date'}>
                                                    <DatePicker
                                                        value={systemStartDate ? moment(systemStartDate) : null}
                                                        onChange={(date, dateString) => setSystemStartDate(dateString)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'System End Date'}>
                                                    <DatePicker
                                                        value={systemEndDate ? moment(systemEndDate) : null}
                                                        onChange={(date, dateString) => setSystemEndDate(dateString)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Domain Start Date'}>
                                                    <DatePicker
                                                        value={domainStartDate ? moment(domainStartDate) : null}
                                                        onChange={(date, dateString) => setDomainStartDate(dateString)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Domain End Date'}>
                                                    <DatePicker
                                                        value={domainEndDate ? moment(domainEndDate) : null}
                                                        onChange={(date, dateString) => setDomainEndDate(dateString)}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Promotion'}>
                                                    <Input
                                                        value={promotion}
                                                        onChange={(event) => setPromotion(event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default Customer;
