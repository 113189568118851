import { create } from 'zustand'
import { persist,createJSONStorage } from 'zustand/middleware'

const init = {
    isOpenModalLogout:false,
    testValue:true
}
export const useModalStore = create((set) => ({
        modal:init,
        onChangeModalLogout: (data) => set((state) => ({
            modal:{
                ...state.modal,
                isOpenModalLogout: data,
            }
        })),
        // removeUser: () => set({user:init}),
    })
)



export function handle401Error(response) {
    if (response.status === 401) {
        useModalStore.setState((prev) => ({
            modal:{
                ...prev.modal,
                isOpenModalLogout: true,
            }
        }));
    }
    return
}