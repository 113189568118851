import React, { useEffect } from 'react';
// import { del, get } from '../../utils';
import { Table } from 'antd';
import './styles.scss'

function TypeTable(props) {
    const columns = [
        {
            title: 'Type ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (record.id).toString().padStart(10, "0")
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'Published',
        //     dataIndex: 'published',
        //     key: 'published',
        //     render: (text, record) => record.published ? <span style={{ color: "green" }}>{"Yes"}</span> : <span style={{ color: "red" }}>{"No"}</span>
        // },
    ];

    useEffect(() => {
    }, []);

    return (
        <div className="project-table-wrapper">
            <Table
                className="project-table"
                loading={props.loading}
                dataSource={props.dataSource}
                columns={columns}
                rowSelection={{
                    ...props.rowSelection,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => props.onClickRow(record),
                    };
                }}
            />
      
        </div>
    );
}

export default TypeTable;
