import React, { useEffect } from 'react';
// import { del, get } from '../../utils';
import { Table } from 'antd';
import './styles.scss'

function RibbonTable(props) {
    const columns = [
        {
            title: 'Color ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (record.id).toString().padStart(10, "0")
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            render: (text, record) => record.color_left && record.color_right ?
                <div style={{ background: `linear-gradient(to right, ${record.color_left}, ${record.color_right})`, width: "100%", height: "20px", borderRadius: "12px" }}></div> : ''
        },
        // {
        //     title: 'Color',
        //     dataIndex: 'color',
        //     key: 'color',
        //     render: (text, record) => record.color_left && record.color_right ?
        //         <div className='ribbon'>
        //             <div className="content" style={{ backgroundImage: `linear-gradient(to right, ${record.color_left}, ${record.color_right})` }}>
        //                 {record.name}
        //             </div>
        //         </div>
        //         : ''
        // },
        // {
        //     title: 'Published',
        //     dataIndex: 'published',
        //     key: 'published',
        //     render: (text, record) => record.published ? <span style={{ color: "green" }}>{"Yes"}</span> : <span style={{ color: "red" }}>{"No"}</span>
        // },
    ];

    useEffect(() => {

    }, []);

    return (
        <div className="project-table-wrapper">
            <Table
                className="project-table"
                loading={props.loading}
                dataSource={props.dataSource}
                columns={columns}
                rowSelection={{
                    ...props.rowSelection,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => props.onClickRow(record),
                    };
                }}
            />

        </div>
    );
}

export default RibbonTable;
