import React, { useState, useRef, useEffect, Fragment } from 'react';
import ReactQuill from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { Modal } from 'antd';
import 'react-quill/dist/quill.snow.css';
import ImageSelection from '../Modal/ImageSelection';

const InputTextEditor = (props) => {
    const quillRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [modules, setModules] = useState({});

    useEffect(() => {
        const Quill = ReactQuill.Quill;
        Quill.register('modules/imageResize', ImageResize);

        const Font = Quill.import('formats/font');
        if (!Font.whitelist || Font.whitelist.length === 0) {
            Font.whitelist = ["Sarabun", "Sans-Serif", "Serif", "Monospace"];
            Quill.register(Font, true);
        }

        setModules({
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],
                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': Font.whitelist }],
                    [{ 'align': [] }],
                    ['link', 'image'],
                ],
                handlers: {
                    image: handleImageClick
                }
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize', 'Toolbar']
            },
        });
    }, []);

    const handleImageClick = () => {
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleImageSelect = (selectedImage) => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection(true);
        if (editor && selectedImage) {
            editor.insertEmbed(range.index, 'image', selectedImage, 'api');
            handleCancel();
        }
    };

    return (
        <Fragment>
            <Modal
                className="modal-check-width"
                visible={showModal}
                onCancel={handleCancel}
                footer={null}
                width="auto"
                style={{ maxWidth: "1100px" }}
                destroyOnClose={true}
            >
                <h2>Image</h2>
                <ImageSelection onSelectImage={handleImageSelect} onClose={handleCancel} />
            </Modal>

            <ReactQuill
                ref={quillRef}
                value={props.value}
                onChange={props.onChangeText}
                modules={modules}
                readOnly={props.disabled}
            />
        </Fragment>
    );
};

export default InputTextEditor;
