import React, { useEffect, useState, useRef } from 'react';
import { get } from '../../utils';
import { Table, Input, Checkbox, Button, Space } from 'antd';

import './styles.scss'

const { Search } = Input;

function ProjectTable(props) {
    const checkBoxInput = useRef(null);

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false)

    const [searchName, setSearchName] = useState("")
    const [searchVersion, setSearchVersion] = useState("")
    const [searchHighlighted, setSearchHighlighted] = useState([1,0])
    const [searchIsShowAllRecommended, setSearchIsShowAllRecommended] = useState([1,0])

    const getColumnSearchProps = (dataIndex,onSearch) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            key={dataIndex}
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Search 
                onSearch={value => onSearch(value)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            <Space>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    close();
                }}
            >
                close
            </Button>
            </Space>
        </div>
        ),
    });

    const options = [
        {
            label: 'Yes',
            value: 1,
        },
        {
            label: 'No',
            value: 0,
        },
    ];
    const getColumnSearchCheckBoxProps = (dataIndex,onSearch) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            key={dataIndex}
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Checkbox.Group
                ref={checkBoxInput}
                options={options}
                defaultValue={[0,1]}
                onChange={(value)=> onSearch(value)}
            />
            <Space>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    close();
                }}
            >
                close
            </Button>
            </Space>
        </div>
        ),
    });
    
    const columns = [
        {
            title: 'Function ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (record.id).toString().padStart(10, "0")
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '50%',
            ...getColumnSearchProps('name',(value)=>setSearchName(value)),
        },
        {
            title: 'Version',
            dataIndex: 'version_name',
            key: 'version_name',
            ...getColumnSearchProps('version_name',(value)=>setSearchVersion(value)),
        },
        {
            title: 'Highlighted',
            dataIndex: 'highlighted',
            key: 'highlighted',
            render: (text, record) =>{
                const status = {
                    1: {label: "Yes", color: "green"},
                    0: {label: "No", color: "red"},
                }

                return (
                    status[text] ? 
                    <span style={{ color: status[text].color }}>{status[text].label}</span> 
                    :
                    null
                )
            },
            ...getColumnSearchCheckBoxProps('highlighted',(value)=>setSearchHighlighted(value)),
        },
        {
            title: 'Show All Recommended',
            dataIndex: 'is_show_all_recommended',
            key: 'is_show_all_recommended',
            render: (text, record) =>{
                const status = {
                    1: {label: "Yes", color: "green"},
                    0: {label: "No", color: "red"},
                }
                
                return (
                    status[text] ? 
                    <span style={{ color: status[text].color }}>{status[text].label}</span> 
                    :
                    null
                )
            },
            ...getColumnSearchCheckBoxProps('is_show_all_recommended',(value)=>setSearchIsShowAllRecommended(value)),
        },
        {
            title: 'Published',
            dataIndex: 'published',
            key: 'published',
            render: (text, record) => record.published ? <span style={{ color: "green" }}>{"Yes"}</span> : <span style={{ color: "red" }}>{"No"}</span>
        },
    ];

    useEffect(() => {
        fetchTable({searchName,searchVersion,searchHighlighted,searchIsShowAllRecommended})       
    }, [searchName,searchVersion,searchHighlighted,searchIsShowAllRecommended]);

    const fetchTable = ({searchName,searchVersion,searchHighlighted,searchIsShowAllRecommended}) =>{
        setLoading(true)
        let url = '/api/function?is_delete=0'
        if(searchName){
            url += '&search_name=' + searchName
        }
        if(searchVersion){
            url += '&search_version=' + searchVersion
        }

        if(searchHighlighted.length){
            url += '&highlighted=' + JSON.stringify(searchHighlighted)
        }
        if(searchIsShowAllRecommended.length){
            url += '&is_show_all_recommended=' + JSON.stringify(searchIsShowAllRecommended)
        }

        return get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setDataSource(responses)
                setLoading(false)
                return
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
                return
            }) 
    }

    return (
        <div className="project-table-wrapper">
            {/* <div className='search-container' style={{ display: 'felx' }}>
                <Form>
                <Form.Item
                    label={"Name"}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <Search onSearch={value => setSearchName(value)}/>
                </Form.Item>
                <Form.Item
                    label={"Version"}
                    labelCol={{
                    span: 8,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                >
                    <Search onSearch={value => setSearchVersion(value)}/>
                </Form.Item>
                </Form>
                
            </div> */}
            <Table
                className="project-table"
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => props.onClickRow(record),
                    };
                }}
            />
        </div>
    );
}

export default ProjectTable;
