import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, Card, Button, Spin, DatePicker } from 'antd';
import moment from 'moment'
import { get, put, post } from '../../utils';
import FunctionTable from './Table';

import './styles.scss'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};

function Version() {
    const [visable, setVisable] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [timeCreate, setTimecreate] = useState(null)
    const publishedList = [
        { value: 0, label: 'No' },
        { value: 1, label: 'Yes' }
    ]

    const [projectId, setProjectId] = useState(null)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [published, setPublished] = useState(0)

    useEffect(() => {
        let url = '/api/project'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setProjectList(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }, []);

    const onclickCreateButton = () => {
        setVisable(true)
    }

    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisable(true)
        setLoading(true)
        let url = '/api/version/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setProjectId(response.project_id)
                    setName(response.name)
                    setDescription(response.description)
                    setPublished(response.published)
                    setTimecreate(response.timeCreate)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickSaveButton = () => {
        setLoading(true)
        let url = '/api/version'
        let update = post
        let data = {
            project_id: projectId,
            name: name,
            description: description,
            timecreate: timeCreate,
            published: published
        }
        if (editId) {
            url = '/api/version/' + editId
            update = put
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                setEditId(null)
                setVisable(false)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickCancelButton = () => {
        setEditId(null)
        setVisable(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Version'
        }
        if (visable) {
            return 'Create - Version'
        }
        return 'Version'
    }

    return (
        <div className="function-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    {!visable ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visable ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visable ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visable ?
                <FunctionTable onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="function-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="function-main-card" >
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Project'}>
                                            <Select
                                                value={projectId}
                                                onChange={(value) => setProjectId(value)}
                                            >
                                                {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Name'}>
                                            <Input
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Description'}>
                                            <TextArea
                                                autosize={{ minRows: 10 }}
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'System Start Date'}>
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                value={timeCreate ? moment(timeCreate) : null}
                                                onChange={(date, dateString) => setTimecreate(dateString)}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Published'}>
                                            <Select
                                                value={published}
                                                onChange={(value) => setPublished(value)}
                                            >
                                                {publishedList.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default Version;
