import React, { useState, useRef, useEffect } from 'react';
import { post, get, ppost } from '../../utils';
import { Form, Tabs, Button, Input, Row, Col, Card, notification } from 'antd';
import { uniqBy, unionWith, isEqual } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ExclamationCircleOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import envData from '../../environment.json';
const protocol = envData.protocol
const hostname = envData.hostname
const port = envData.port
const urlUpload = `${protocol}://${hostname}:${port}/images/`
const { TabPane } = Tabs;
const FormItem = Form.Item;
const mapFormItemLayout = {
    labelCol: {
        md: { span: 24 },
        lg: { span: 5 },
    },
    wrapperCol: {
        md: { span: 24 },
        lg: { span: 24 },
    },
};

const ImageSelection = ({ onSelectImage, onClose }) => {

    // Tab Upload Variable
    const [imageName, setImageName] = useState('')
    const [imageUrl, setImageUrl] = useState('') // Image url not in use



    const fileRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    // End of Tab Upload Variable

    // Tab SelectedImage Variable
    const [images, setImages] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    // End of Tab SelectedImage Variable

    // Tab Url Variable
    const [nameUrl, setNameUrl] = useState('')
    // End of Tab SelectedImage Variable




    const fileSelectRef = useRef(null);

    useEffect(() => {
        getImagesAll();
    }, []);

    const getImagesAll = () => {
        let url = "/api/image/table";
        get(url)
            .then((responses) => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                setImages(responses.rows)
            })
            .catch(err => {
                console.log(err.message)
            });
    };

    const handleImageClick = (image) => {
        setSelectedImage(image)
    };

    const selectImageUpload = (fileNameFromServer) => {
        const quillWithImage = urlUpload + fileNameFromServer;
        onSelectImage(quillWithImage);
    };

    const selectImage = () => {
        if (nameUrl) {
            const quillWithImage = `${nameUrl}`;
            onSelectImage(quillWithImage);
            onClose();
        }
        if (selectedImage) {
            const quillWithImage = `${urlUpload}${selectedImage.image_file_name}`;
            onSelectImage(quillWithImage);
            onClose();
        }
    };

    const uploadFileToServer = (formData, done) => {
        ppost('/api/upload/image', formData)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                done(response.fileName);
            })
            .catch(err => {
                done(err);
                console.log(err.message);
            });
    };

    const updateImage = () => {
        validateBeforeUpdate((result) => {
            if (result.validate === false) {
                openNotification(result.message, result.description)
                return
            }
            let url = '/api/image/';
            let update = post;
            let listFile = fileList.filter(file => {
                return Object.keys(file).length !== 1;
            });
            let formData = new FormData();
            formData.append('image', listFile[0])
            uploadFileToServer(formData, (fileNameFromServer) => {
                let data = {
                    name: imageName,
                    image_file_name: fileNameFromServer,
                    image_url: imageUrl,
                };
                selectImageUpload(fileNameFromServer);
                onClose();
                update(url, data)
                    // .then((response) => {
                    // })
                    .catch((error) => {
                        console.log("Error: ", error)
                    });
            });
        });

    };

    const validateBeforeUpdate = (done) => {
        let validateResult = true
        let errorMessage = 'Invalid Input'
        let errorDescription = 'Please Input name of image and image correctly.'

        if (!imageName) {
            validateResult = false
            errorDescription = "Image name not found"
        }
        if (imageName.length > 255) {
            validateResult = false
            errorDescription = "Image name must not more length 255 characters"
        }
        if (fileList.length < 1) {
            validateResult = false
            errorDescription = "Image file not found"
        }
        const result = {
            validate: validateResult,
            message: errorMessage,
            description: errorDescription
        }
        // return result
        done(result)

    };

    const handleFilesInput = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            // Check file type
            if (['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
                // Handle valid file type
                const newFileList = unionWith(fileList, [file], isEqual); // Assuming fileList is defined somewhere
                const uniqueFileList = uniqBy(newFileList, 'name');
                setFileList(uniqueFileList);

                // Example of setting file preview
                const filePreviewUrl = URL.createObjectURL(file);
                setFilePreview(filePreviewUrl);
            } else {
                // Handle invalid file type
                openNotification('File type is not allowed to upload.', 'File type is not allowed to upload.');
            }
        }
    };

    const deleteSelectedFile = (index) => {
        const tempFileList = fileList.slice();
        const fileName = tempFileList[index].name;
        tempFileList.splice(index, 1);
        setFileList(tempFileList);
        setFilePreview(null);

        if (Object.keys(fileList[index]).length === 1) {
            setDeleteFileList([...deleteFileList, fileName]);
        }
    };


    const openNotification = (title, description) => {
        notification.open({
            message: title,
            description: description,
            icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        });
    };

    return (
        <div className="image-selection-modal" style={{ width: 'auto' }}>
            <Tabs type="card">
                <TabPane tab={'Upload'} key="1" forceRender={true} >
                    <Form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                        <div className="centered-form-container">
                            <div className="form-content">
                                <Row gutter={[24]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-image-url'>
                                        <FormItem className='form-item-image' {...mapFormItemLayout}>
                                            <div className='image-url-one' style={{ marginBottom: '10px' }}><h2 style={{ color: '#555555' }}>{'Image name'}</h2></div>
                                            <Input className='flex' style={{ marginBottom: '10px' }} value={imageName} onChange={(event) => setImageName(event.target.value)} />
                                            <div style={{ color: 'gray' }}>{'Please enter your image name'}</div>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-image-url'>
                                        <FormItem className='form-item-image' {...mapFormItemLayout}>
                                            <div className='image-url-one' style={{ marginBottom: '10px' }}><h2 style={{ color: '#555555' }}>{'Image'}</h2></div>
                                            <Button className='flex' style={{ marginBottom: '10px' }}
                                                value={'large'}
                                                onClick={() => fileRef.current.click()}
                                                disabled={fileList.length > 0 ? true : false}
                                            >
                                                {/* <Icon type="upload" />{'Upload'} */}
                                                <UploadOutlined /> Upload
                                            </Button>
                                            <input type="file" ref={fileRef} id="fileSelect" accept="image/*" onChange={(event) => handleFilesInput(event)} style={{ display: 'none' }} />
                                            <div className="sale-file-attach-container">
                                                {fileList.map((file, i) =>
                                                    <div className="sale-list-file" key={i}>
                                                        <a target="_blank" rel="noopener" href={file.name}>{file.name}</a>
                                                        <span className="delete-file-icon" onClick={() => deleteSelectedFile(i)}>
                                                            {/* <Icon type="close" style={{ fontSize: 10, cursor: 'pointer' }} /> */}
                                                            <CloseOutlined style={{ fontSize: 10, cursor: 'pointer' }} />
                                                        </span>
                                                    </div>)}
                                            </div>
                                            <div style={{ color: 'gray' }}>{'Please upload your image'}</div>
                                        </FormItem>
                                        {filePreview ?
                                            <Row style={{ textAlign: 'center' }}>
                                                <Col span={24}>
                                                    <Card style={{ background: 'none', textAlign: 'center' }}>
                                                        <img style={{ maxWidth: '200px' }} src={filePreview} alt="Preview" />
                                                    </Card>
                                                </Col>
                                            </Row>
                                            : null}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Button onClick={() => updateImage()} style={{ position: 'absolute', right: '0', bottom: '0' }} >Add Image</Button>
                    </Form>
                </TabPane>
                <TabPane tab={'SelectedImage'} key="2" forceRender={true}>
                    <Form style={{ display: 'flex', height: '500px' }}>
                        <div className="image-list-container" style={{ height: '400px', overflowY: 'auto' }}  >
                            <Row gutter={[16, 8]}>
                                {images.map((image, i) => (
                                    <Col span={6} key={i} style={{ width: '250px', height: '170px' }}>
                                        <style>
                                            {`
                                            .image-item {
                                            width: 100%;
                                            height: 100%;
                                            padding: 10px;
                                            cursor: pointer;
                                            }
                                            .image-item.selected {
                                            border: 2px solid red !important; 
                                            }
                                        `}
                                        </style>
                                        <LazyLoadImage
                                            className={`image-item ${selectedImage === image ? 'selected' : ''}`}
                                            onClick={() => handleImageClick(image)}
                                            style={{
                                                width: '100%', height: '100%', padding: '10px', objectFit: 'contain'
                                            }}
                                            src={`${urlUpload}${image.image_file_name}`}
                                            alt={image.name}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <Button onClick={() => selectImage()} style={{ position: 'absolute', right: '0', bottom: '0' }} >Add Image</Button>
                    </Form>
                </TabPane>
                <TabPane tab={'Url'} key="3" forceRender={true}>
                    <Form style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                        <div className="centered-form-container">
                            <div className="form-content">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-image-url'>
                                    <FormItem className='form-item-image' {...mapFormItemLayout}>
                                        <div className='image-url-one' style={{ marginBottom: '10px' }}><h2 style={{ color: '#555555' }}>{'Image url'}</h2></div>
                                        <Input className='flex' style={{ marginBottom: '10px', marginRight: '250px' }} type="text" value={nameUrl} onChange={(event) => setNameUrl(event.target.value)} />
                                        <div style={{ color: 'gray' }}>{'Please insert your image link'}</div>
                                    </FormItem>
                                </Col>
                            </div>
                        </div>
                        <Button onClick={() => selectImage()} style={{ position: 'absolute', right: '0', bottom: '0' }} >Add Image</Button>
                    </Form>
                </TabPane>
            </Tabs>
        </div>
    );
};


export default ImageSelection;
