import { ppost } from './axios'

export const uploadFileToServer = async (formData, setUploadProgress = null,folder = null) => {
    let url = '/api/file/upload'
    let folderName = 'default'
    if(folder){
        folderName = folder
    }
    url += '/' + folderName
    
    return await ppost(url, formData ,setUploadProgress)
        .then(response => {
            return response
        })
        .catch(err => {
            console.log(err.message)
            return []
        })
}