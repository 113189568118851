import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Input, Card, Button, Spin, Popconfirm, notification } from 'antd';
import { ExclamationCircleOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { uniqBy, unionWith, isEqual } from 'lodash'
import { get, put, post, ppost, del } from '../../utils';
import ImageTable from './Table';
import './styles.scss'
import envData from '../../environment.json';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};
const protocol = envData.protocol
const hostname = envData.hostname
const port = envData.port
const link = `${protocol}://${hostname}:${port}`


function ImagePage() {
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visible, setVisible] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('')
    const [imageFileName, setImageFileName] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    // const fileInputRef = useRef(null);

    //File 
    const [fileList, setFileList] = useState([]);
    const [deleteFileList, setDeleteFileList] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const fileSelectRef = useRef(null);


    useEffect(() => {
        getTable()
    }, []);

    const onclickCreateButton = () => {
        setVisible(true)
        setInitialState()
    }
    const setInitialState = () => {
        setName("")
        setImageUrl("")
        setFileList([])
        setDeleteFileList([])
        setFilePreview(null)
    }
    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisible(true)
        setLoading(true)
        let url = '/api/image/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setName(response.name)
                    setImageFileName(response.image_file_name)
                    setImageUrl(response.image_url)
                    setFileList(
                        response.image_file_name === "" || response.image_file_name == null ? [] : response.image_file_name.split(",").map(name => {
                            return {
                                name: name
                            }
                        })
                    )
                    setFilePreview(`${link}/images/${response.image_file_name}`)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    const onclickSaveButton = () => {
        const validate = validateBeforeUpdate()
        if (!validate.validate) {
            openNotification(validate.message, validate.description)
            return
        }
        setLoading(true)
        let url = '/api/image'
        let update = post
        if (editId) {
            url = '/api/image/' + editId
            update = put
        }
        saveDeleteFileList()
        let listFile = fileList.filter(file => Object.keys(file).length !== 1);
        if (listFile.length === 0) {
            let data = {
                name: name,
                image_file_name: imageFileName,
                image_url: imageUrl,
            }
            update(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    setEditId(null)
                    setVisible(false)
                    setLoading(false)
                    getTable()
                })
                .catch(err => {
                    console.log(err.message)
                    setLoading(false)
                })
        } else {
            let formData = new FormData();
            formData.append('image', listFile[0])
            uploadFileToServer(formData, (fileNameFromServer) => {
                let data = {
                    name: name,
                    image_file_name: fileNameFromServer,
                    image_url: imageUrl,
                };
                update(url, data)
                    .then(response => {
                        if (response.status >= 400) {
                            throw new Error("Bad response from server");
                        }
                        setEditId(null);
                        setVisible(false);
                        setLoading(false);
                        getTable();
                    })
                    .catch(err => {
                        console.error("Error uploading file:", err.message);
                        setLoading(false);
                    })
            })
        }
    }

    const onclickCancelButton = () => {
        setEditId(null)
        setVisible(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Image'
        }
        if (visible) {
            return 'Create - Image'
        }
        return 'Image'
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const deleteItem = () => {
        setLoading(true)
        let url = '/api/image'
        const data = {
            id: selectedRowKeys
        }
        const dataSourceImageDeleteFiles = dataSource.filter(item => selectedRowKeys.includes(item.id));
        let imageFilesNames = dataSourceImageDeleteFiles.map(item => item.image_file_name);
        console.log("imageFilesNames", imageFilesNames)
        imageFilesNames.map((fileName) => {
            deleteFile(fileName)
        })
        del(url, data)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setLoading(false)
                getTable()
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    const getTable = () => {
        setLoading(true)
        let url = '/api/image'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                const updatedResponses = responses.map((item) => {
                    return {
                        ...item,
                        key: item.id
                    };
                });
                setDataSource(updatedResponses)
                setSelectedRowKeys([])
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const uploadFileToServer = (formData, done) => {
        ppost('/api/upload/image', formData)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                done(response.fileName[0])
            })
            .catch(err => {
                console.log(err.message);
                setLoading(false)
            });
    };

    const handleFilesInput = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];

            // Check file type
            if (['image/jpg', 'image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
                // Handle valid file type
                const newFileList = unionWith(fileList, [file], isEqual); // Assuming fileList is defined somewhere
                const uniqueFileList = uniqBy(newFileList, 'name');
                setFileList(uniqueFileList);

                // Example of setting file preview
                const filePreviewUrl = URL.createObjectURL(file);
                setFilePreview(filePreviewUrl);
            } else {
                // Handle invalid file type
                openNotification('File type is not allowed to upload.', 'File type is not allowed to upload.');
            }
        }
    };


    const deleteFile = (fileName) => {
        post('/api/delete/image', { fileName })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
            })
            .catch(err => {
                console.log(err.message);
                setLoading(false)
            });
    };

    const saveDeleteFileList = () => {
        deleteFileList.forEach(file => {
            deleteFile(file);
        });
    };

    const deleteSelectedFile = (index) => {
        if (Object.keys(fileList[index]).length === 1) {
            let tempDeleteFileList = [...deleteFileList, fileList[index].name];
            let tempFileList = fileList.slice();
            tempFileList.splice(index, 1);
            setDeleteFileList(tempDeleteFileList);
            setFileList(tempFileList);
            setFilePreview(null);
        } else {
            let tempFileList = fileList.slice();
            tempFileList.splice(index, 1);
            setFileList(tempFileList);
            setFilePreview(null);
        }
    };

    const validateBeforeUpdate = () => {
        let validateResult = true
        let errorMessage = 'Invalid Input'
        let errorDescription = 'Please Input name of image and image correctly.'

        if (!name) {
            validateResult = false
            errorDescription = "Image name not found"
        }
        if (name.length > 255) {
            validateResult = false
            errorDescription = "Image name must not more length 255 characters"
        }
        if (fileList.length < 1) {
            validateResult = false
            errorDescription = "Image file not found"
        }
        const result = {
            validate: validateResult,
            message: errorMessage,
            description: errorDescription
        }
        return result
        // done(result)

    };

    // const setSendingStatus = (status) => {
    //     // Your logic to set sending status
    // };

    // const showAlertDownload = () => {
    //     // Your logic to show alert for download
    // };

    // const showAlertMsg = (message) => {
    //     // Your logic to show alert message
    // };

    // const showAlertMsgStorage = () => {
    //     // Your logic to show alert for storage
    // };

    const openNotification = (title, description) => {
        notification.open({
            message: title,
            description: description,
            icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        });
    }

    const uploadClickHandler = () => {
        fileSelectRef.current.click();
    }

    return (
        <div className="function-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    <Popconfirm
                        title="Delete the image"
                        description="Are you sure to delete this image?"
                        onConfirm={() => deleteItem()}
                        onCancel
                        okText="Yes"
                        cancelText="No"
                        disabled={selectedRowKeys.length <= 0}
                    >
                        {!visible ? <Button disabled={selectedRowKeys.length <= 0} type="danger">{'Delete'}</Button> : null}
                    </Popconfirm>
                    {!visible ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visible ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visible ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visible ?
                <ImageTable dataSource={dataSource} loading={loading} rowSelection={rowSelection} onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="function-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="function-main-card" >
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Image name'} required>
                                            <Input value={name} onChange={(event) => setName(event.target.value)} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem
                                            {...formItemLayout}
                                            label={'Image'}
                                        >
                                            <Button
                                                value={'large'}
                                                type="primary"
                                                onClick={uploadClickHandler}
                                                disabled={fileList.length >= 1 ? true : false}
                                            >
                                                <UploadOutlined /> Upload
                                            </Button>
                                            <input type="file" ref={fileSelectRef} id="fileSelect" accept="image/*" onChange={handleFilesInput} style={{ display: 'none' }} />
                                            <div className="sale-file-attach-container">
                                                {fileList.map((file, i) =>
                                                    <div className="sale-list-file" key={i}>
                                                        <a target="_blank" rel="noopener noreferrer" href={link + '/images/' + file.name}>{file.name}</a>
                                                        <span className="delete-file-icon" onClick={() => deleteSelectedFile(i)}>
                                                            <CloseOutlined style={{ fontSize: 10, cursor: 'pointer' }} />
                                                        </span>
                                                    </div>)}
                                            </div>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                            <Row style={{ textAlign: 'center', display: filePreview || (fileList.length > 0) ? '' : 'none' }}>
                                <Col span={24}>
                                    <Card style={{ background: 'none', textAlign: 'center' }}>
                                        <img
                                            style={{ maxWidth: '100%', maxHeight: '500px', display: filePreview || (fileList.length > 0) ? '' : 'none' }}
                                            src={filePreview}
                                            alt="Uploaded File"
                                        />   </Card>
                                </Col>
                            </Row>

                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default ImagePage;




