import { create } from 'zustand'
import { persist,createJSONStorage } from 'zustand/middleware'

const init = {
    id: 0,
    access_token:'',
    username:'',
    isAuthenticated:false
}
export const useUserStore = create(
    persist((set) => ({
        user:init,
        increaseUser: (data) => set((state) => ({
            user:{
                id: data.id,
                access_token: data.access_token,
                username: data.username,
                isAuthenticated: true
            }
        })),
        removeUser: () => set({user:init}),
    }),
    {
      name: 'user-storage', // unique name
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    })
)