// import fetch from 'isomorphic-fetch';
import envData from '../environment.json'
// import envDataDev from '../environment-dev.json'

// const PORT = 80
const PORT = 443
// const PORT = 4040

function call(url, method, data) {
    // const { hostname, protocol } = window.location
    const protocol = envData.protocol
    const hostname = envData.hostname
    const port = envData.port

    // const port = 443
    // const hostname = 'version-api.codexhouse.com'
    const serverUrl = `${protocol}://${hostname}:${port}${url}`
    console.log("serverUrl", serverUrl)
    return fetch(serverUrl, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        },
        body: JSON.stringify(data)
    })
        .then(parseJSON)
        .then(checkHttpStatus)
        .catch(error => {
            console.log("error at utils.index.js line 21")
            console.log(error)
            if (typeof error.response === 'undefined') {
                error.response = {
                    status: 408,
                    message: 'Cannot connect to the server'
                }
            }
            throw error
        })
}
function ccall(url, method, data) {
    const protocol = envData.protocol
    const hostname = envData.hostname
    const port = envData.port
    const serverUrl = `${protocol}://${hostname}:${port}${url}`
    console.log("serverUrl", serverUrl)
    return fetch(serverUrl, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
        },
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('id_token')
        },
        body: data
    })
        .then(parseJSON)
        .then(checkHttpStatus)
        .catch(error => {
            console.log("error at utils.index.js line 21")
            console.log(error)
            if (typeof error.response === 'undefined') {
                error.response = {
                    status: 408,
                    message: 'Cannot connect to the server'
                }
            }
            throw error
        })
}

function checkHttpStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response.body
    } else {
        var error = new Error(response.statusText)
        error.response = response.body
        throw error
    }
}

function parseJSON(response) {
    return response.json()
        .then(function (body) {
            return {
                status: response.status,
                statusText: response.statusText,
                body: body
            }
        })
        .catch(function (e) {
            return response;
        })
}

export function get(url) {
    return call(url, 'get')
}

export function ppost(url, data) {
    return ccall(url, 'post', data)
}

export function post(url, data) {
    return call(url, 'post', data)
}

export function put(url, data) {
    return call(url, 'put', data)
}

export function del(url, data) {
    return call(url, 'delete', data)
}