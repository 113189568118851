import React,{useEffect,Suspense,lazy} from 'react'
import { BrowserRouter,Routes,Route,Navigate } from 'react-router-dom'
import './App.css';
import { post } from "./utils/axios";
import {useUserStore} from "./features/storeUser";
import { useModalStore } from "./features/storeModal";


import LayoutPage from './component/LayoutPage'

const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));

const Notfound = lazy(()=>import("./pages/Notfound"))

const ModalLogout = lazy(() => import('./component/Modal/Logout'));

function App() {
  return (
    <Suspense fallback={<span>Loading ....</span>}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={(
            <RenderLayout>
              <LayoutPage />
            </RenderLayout>
          )} />
          <Route path='/login' element={<Login/>} />
          <Route path='/logout' element={<Logout/>} />
          <Route path='*' element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

function RenderLayout({children}){
  const user = useUserStore((state)=>state.user)
  const isOpenModalLogout = useModalStore((state)=>state.modal.isOpenModalLogout)

  useEffect(()=>{
    getAuth()
  },[])

  const getAuth = async () =>{
    try{
      let url = '/api/auth/user'
      const dataUser = await post(url)
      console.log('dataUser', dataUser)
      return dataUser
    }
    catch(error){
      console.log('error', error)
    }
    

  }

  if(!user.isAuthenticated){
    return(<Navigate to='/login' />)
  }

  return(
    <div className="App">
      {children}
      {isOpenModalLogout ? <ModalLogout /> : null }
    </div>
  )
}


export default App;
