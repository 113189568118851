import {uploadFileToServer} from './fetchData'

export async function setUpdateDataAndUpload({datasource, type,setUploadProgress=null,folder = null}){
    try{
        if(datasource && datasource.length > 0){
            let fileUploadName=[]
            const filterNotSize = datasource.filter(i=>!i.size).map(i=>i.name)
            const filterWithSize = datasource.filter(i=>i.size)

            if(filterWithSize.length){
                let formData = new FormData();
                filterWithSize.forEach((item,index) => {
                    formData.append(type + index, item)
                })

                let fileNameList = await uploadFileToServer(formData,setUploadProgress,folder)
                fileUploadName = fileNameList.fileName
            }
            
            return {success:true, fileName:[...filterNotSize,...fileUploadName]}
        }
        return {success:true, fileName:[]}
    }catch(error){
        return {success:false, error}
    }
};
