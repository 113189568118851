import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    StarOutlined,
    UserOutlined,
    TagOutlined,
    FunctionOutlined,
    SafetyCertificateOutlined,
    LogoutOutlined,
    FileImageOutlined
} from '@ant-design/icons';

import Customer from '../../container/Customer'
import Project from '../../container/Project'
import Version from '../../container/Version'
import Function from '../../container/Function'
import Label from '../../container/Label'
import Type from '../../container/Type'
import Category from '../../container/Category'
import Ribbon from '../../container/Ribbon'
import Image from '../../container/Image'


import logoCodexTransparent from '../../resource/images/logo_codex_transparent.png'
import logoCodexWhite from '../../resource/images/logo_codex_white.png'
import './styles.scss'
const { Header, Sider, Content } = Layout;
function LayoutPage() {
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false)
    const [selectedKeys, setSelectedKeys] = useState('1')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const componentList = [
        { key: '1', name: 'customer', label: 'Customer', component: <Customer />, icon: <UserOutlined /> },
        { key: '2', name: 'project', label: 'Project', component: <Project />, icon: <StarOutlined /> },
        { key: '3', name: 'version', label: 'Version', component: <Version />, icon: <SafetyCertificateOutlined /> },
        { key: '4', name: 'function', label: 'Function', component: <Function />, icon: <FunctionOutlined /> },
        { key: '5', name: 'label', label: 'Label', component: <Label />, icon: <TagOutlined /> },
        { key: '6', name: 'type', label: 'Type', component: <Type />, icon: <FunctionOutlined /> },
        { key: '7', name: 'category', label: 'Category', component: <Category />, icon: <MenuUnfoldOutlined /> },
        { key: '8', name: 'ribbon', label: 'Ribbon', component: <Ribbon />, icon: <MenuFoldOutlined /> },
        { key: '9', name: 'image', label: 'Image', component: <Image />, icon: <FileImageOutlined /> },
    ]

    const onClickLeftMemu = (item, index) => {
        setSelectedKeys(item.key)
        setSelectedIndex(index)
    }
    return (
        <Layout className="layout-page-wrapper">
            <Sider trigger={null} collapsible collapsed={collapsed}>
                {collapsed ?
                    <img className="logo-collapsed" src={logoCodexWhite} />
                    :
                    <img className="logo" src={logoCodexTransparent} />
                }
                <Menu theme="dark" mode="inline" selectedKeys={[selectedKeys]}>
                    {componentList.map((item, index) => {
                        return (
                            <Menu.Item
                                key={item.key}
                                icon={item.icon}
                                onClick={() => onClickLeftMemu(item, index)}
                            >
                                {item.label}
                            </Menu.Item>
                        )
                    })}
                    <Menu.Item
                        key={'logout'}
                        icon={<LogoutOutlined/>}
                        onClick={() => navigate('/logout')}
                    >
                        ออกจากระบบ
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-header-background"> 
                    <div className="icon-wrapper">
                        {collapsed ?
                            <MenuUnfoldOutlined onClick={() => setCollapsed(!collapsed)} style={{ fontSize: 18 }} />
                            :
                            <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} style={{ fontSize: 18 }} />
                        }
                    </div>
                    <h1>{"Codex Version Control"}</h1>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        overflow:"auto"
                    }}
                >
                    {componentList[selectedIndex].component}
                </Content>
            </Layout>
        </Layout>
    );
}

export default LayoutPage;
