import React, { useEffect, useState } from 'react';
import { get } from '../../utils';
import { Table } from 'antd';

import './styles.scss'

function ProjectTable(props) {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Label ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (record.id).toString().padStart(10, "0")
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Project',
            dataIndex: 'project_name',
            key: 'project_name',
        },
        {
            title: 'Deleted',
            dataIndex: 'deleted',
            key: 'deleted',
            render: (text, record) => record.deleted ? <span style={{ color: "red" }}>{"Yes"}</span> : <span style={{ color: "green" }}>{"No"}</span>
        },
    ];

    useEffect(() => {
        setLoading(true)
        let url = '/api/label'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setDataSource(responses)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }, []);

    return (
        <div className="label-table-wrapper">
            <Table
                className="label-table"
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => props.onClickRow(record),
                    };
                }}
            />
        </div>
    );
}

export default ProjectTable;
