import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Card, Button, Spin, Popconfirm } from 'antd';
import { get, put, post, del } from '../../utils';
import TypeTable from './Table';
import './styles.scss'

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
    },
};

function Type() {
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visable, setVisable] = useState(false)
    const [editId, setEditId] = useState(null)
    const [loading, setLoading] = useState(false)
    // const publishedList = [
    //     { value: 0, label: 'No' },
    //     { value: 1, label: 'Yes' }
    // ]
    const [name, setName] = useState('')
    // const [published, setPublished] = useState(0)

    const setInitialState = () => {
        setName("")
        // setPublished(0)
    }

    useEffect(() => {
        getTable()
    }, []);

    const onclickDeleteButton = () => {
        deleteItem()
    }
    const onclickCreateButton = () => {
        setVisable(true)
        setInitialState()
    }

    const onclickEditButton = (record) => {
        setEditId(record.id)
        setVisable(true)
        setLoading(true)

        let url = '/api/type/' + record.id
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (response) {
                    setName(response.name)
                    // setPublished(response.published)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    const onclickSaveButton = () => {
        setLoading(true)
        let url = '/api/type'
        let update = post
        let data = {
            name: name,
            // published: published,
            published: 1, // set default ไว้ยังไม่ได้ใช้
        }

        if (editId) {
            url = '/api/type/' + editId
            update = put
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                setEditId(null)
                setVisable(false)
                setLoading(false)
                getTable()
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const onclickCancelButton = () => {
        setEditId(null)
        setVisable(false)
    }

    const getHeaderTitle = () => {
        if (editId) {
            return 'Edit - Type'
        }
        if (visable) {
            return 'Create - Type'
        }
        return 'Type'
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const deleteItem = () => {
        setLoading(true)
        let url = '/api/type'
        const data = {
            id: selectedRowKeys
        }
        del(url, data)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                setLoading(false)
                getTable()
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    const getTable = () => {
        setLoading(true)
        let url = '/api/type'
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad responses from server");
                }
                const updatedResponses = responses.map((item) => {
                    return {
                        ...item,
                        key: item.id
                    };
                });
                setDataSource(updatedResponses)
                setSelectedRowKeys([])
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    return (
        <div className="function-page-wrapper">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h2>{getHeaderTitle()}</h2>
                </div>
                <div className="action-button-wrapper">
                    <Popconfirm
                        title="Delete the type"
                        description="Are you sure to delete this type?"
                        onConfirm={() => onclickDeleteButton()}
                        onCancel
                        okText="Yes"
                        cancelText="No"
                        disabled={selectedRowKeys.length <=0 }
                    >
                        {!visable ? <Button disabled={selectedRowKeys.length <=0 } type="danger">{'Delete'}</Button> : null}
                    </Popconfirm>
                    {!visable ? <Button type="primary" onClick={() => onclickCreateButton()}>{'Create'}</Button> : null}
                    {visable ? <Button type="primary" onClick={() => onclickSaveButton()}>{'Save'}</Button> : null}
                    {visable ? <Button type="danger " onClick={() => onclickCancelButton()}>{'Cancel'}</Button> : null}
                </div>
            </div>

            {!visable ?
                <TypeTable dataSource={dataSource} loading={loading} rowSelection={rowSelection} onClickRow={(record) => onclickEditButton(record)} />
                :
                <div className="function-main-wrapper">
                    <Spin tip="Loading..." spinning={loading}>
                        <Card className="function-main-card" >
                            <Form>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Name'}>
                                            <Input
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Function'}>
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Function"
                                                value={functionSystem ? newFunction(functionSystem) : undefined}
                                                onChange={handleFunctionChange}
                                            >
                                                {functionList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col> */}
                                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <FormItem {...formItemLayout} label={'Published'}>
                                            <Select
                                                value={published}
                                                onChange={(value) => setPublished(value)}
                                            >
                                                {publishedList.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                            </Select>
                                        </FormItem>
                                    </Col> */}
                                </Row>
                            </Form>
                        </Card>
                    </Spin>
                </div>
            }
        </div>
    );
}

export default Type;
